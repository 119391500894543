import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import ContactBox from "../components/contactBox"
import { Link, animateScroll as scroll } from "react-scroll"
// import ScrollDownArrow from "../components/scrollDownArrow"
import { IoIosShareAlt } from "react-icons/io"

import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import { InView } from "react-intersection-observer"

const Index = ({ data }) => (
  <Layout>
    <SEO title="Forside" description={data.sanityFrontPage.description} />
    <section className="index">
      <div className="introSection" style={{ position: "relative" }}>
        <div className="introText">
          <h1 className="titleFadeIn">{data.sanityFrontPage.title}</h1>
          <p className="descriptionFadeIn">
            {data.sanityFrontPage.description}
          </p>
          <div className="whiteSpace"></div>
          <AniLink
            cover
            bg="#69b56b"
            to="/om-os/"
            className="buttonTemp buttonFadeIn"
          >
            {data.sanityFrontPage.button}
          </AniLink>
        </div>

        <div className="newsBlocWrapper">
          <div className="newsBlocks">
            <AniLink cover bg="#69b56b" to={`/nyheder/`}>
              <span>Nyheder</span>
            </AniLink>
            <div className="indicators">
              <div className="indicator1 activeIndicator"></div>
              <div className="indicator2"></div>
              <div className="indicator3"></div>
            </div>
            {data.allSanityPost.edges.map(function (news, index) {
              index++
              return (
                <AniLink
                  cover
                  bg="#69b56b"
                  to={`/nyheder/${news.node.slug.current}/`}
                >
                  <div className={`upperBox`}>
                    <Img
                      fluid={news.node.mainImage.asset.fluid}
                      alt={news.node.title}
                    />

                    <InView
                      className={`box${index}`}
                      threshold="0.1"
                      onChange={(inView, entry) => {
                        if (inView === true) {
                          initiateActions(entry.target)
                        }
                      }}
                    ></InView>
                  </div>
                </AniLink>
              )
            })}
            {data.allSanityPost.edges.map(function (newsLower) {
              return (
                <div className={`lowerBox lb`}>
                  <div className="textWrapper">
                    <h3>{newsLower.node.title}</h3>
                    <p className="text">
                      {newsLower.node._rawBody[0].children[0].text}
                    </p>
                  </div>
                  <p className="date">{newsLower.node.publishedAt}</p>
                  <AniLink
                    cover
                    bg="#69b56b"
                    to={`/nyheder/${newsLower.node.slug.current}/`}
                  >
                    <IoIosShareAlt />
                  </AniLink>
                </div>
              )
            })}
          </div>
        </div>
        <Img
          fluid={data.sanityFrontPage.image.asset.fluid}
          alt={data.sanityFrontPage.title}
          className="zIndex"
        />

        {/* <ScrollDownArrow /> */}
      </div>
      <InView
        threshold="1"
        onChange={(inView, entry) => {
          if (inView === true) {
            entry.target.firstChild.classList.add("mainSectionInview")
          }
        }}
      >
        <div className="mainSection">
          {/* <InView
            threshold="0.2"
            onChange={(inView, entry) => {
              if (inView === true) {
                entry.target.firstChild.classList.add("quickFadeUp")
              }
            }}
          ></InView> */}
          <div className="gridWrapper">
            {data.sanityFrontPage.linkButton.map(function (infoBox) {
              return (
                <div className="gridItem">
                  <div className="imageWrapper">
                    <Img fluid={infoBox.icon.asset.fluid} alt={infoBox.title} />
                  </div>
                  <h2>{infoBox.title}</h2>
                  <p>{infoBox.description}</p>
                  <div className="whiteSpace"></div>

                  <div className="getRightLink">
                    {getRightLink(infoBox.linkButton)}
                  </div>

                  <div className="whiteSpace"></div>
                </div>
              )
            })}
          </div>
        </div>
      </InView>

      <InView
        threshold="0"
        onChange={(inView, entry) => {
          if (inView === true) {
            entry.target.firstChild.classList.add("newsSectionInview")
          }
        }}
      >
        <div className="newsSection">
          <h2>Nyheder</h2>

          <div className="newsGrid">
            {data.allSanityPost.edges.map(function (news) {
              return (
                <div className="newsItem">
                  <AniLink
                    cover
                    bg="#69b56b"
                    to={`/nyheder/${news.node.slug.current}/`}
                  >
                    <Img
                      fluid={news.node.mainImage.asset.fluid}
                      alt={news.node.title}
                      className="newsPostImage"
                      style={{ height: 200 }}
                    />
                    <h3>{news.node.title}</h3>
                    <AniLink
                      cover
                      bg="#69b56b"
                      className="linkTemp"
                      to={`/nyheder/${news.node.slug.current}/`}
                    >
                      Læs mere
                    </AniLink>
                  </AniLink>
                </div>
              )
            })}
          </div>
          <div className="allNews">
            <AniLink to="/nyheder/" cover bg="#69b56b">
              Se alle nyheder
            </AniLink>
          </div>
        </div>
      </InView>

      <InView
        threshold="0.2"
        onChange={(inView, entry) => {
          if (inView === true) {
            entry.target.firstChild.classList.add("quickFadeUp")
          }
        }}
      >
        <h2 className="activityTitle">Kommende aktiviteter</h2>
      </InView>

      <div className="activitySection">
        <div class="custom-shape-divider-top-1592553075">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <InView
          threshold="0.5"
          onChange={(inView, entry) => {
            if (inView === true) {
              entry.target.firstChild.classList.add("firstActivityInView")
            }
          }}
        >
          <div className="firstActivity">
            <div className="activityInfo">
              <p className="linkTemp">
                {getRightTime(data.sanityAnnualMeeting.calendarInfo.date)}
              </p>
              <h3>{data.sanityAnnualMeeting.title}</h3>
              <BlockContent
                blocks={data.sanityAnnualMeeting._rawDescription}
                projectId="q2jj4zmh"
                dataset="production"
                serializers={serializers}
              />

              <div className="whiteSpace"></div>

              <AniLink
                to="/aarsmoede/"
                cover
                bg="#69b56b"
                className="buttonTemp"
              >
                {data.sanityAnnualMeeting.button}
              </AniLink>
              <div className="whiteSpace"></div>
            </div>

            <div className="contactWrapper">
              <p className="signUp">Tilmeld</p>
              <p className="contactDate">
                {getRightTime(data.sanityAnnualMeeting.calendarInfo.date)}
              </p>
              <ContactBox data={data.sanityAnnualMeeting.title} />
            </div>
          </div>
        </InView>
        <InView
          threshold="0.5"
          onChange={(inView, entry) => {
            if (inView === true) {
              entry.target.firstChild.classList.add("secondActivityInView")
            }
          }}
        >
          {data.allSanityCalendar.edges.map(function (activityInfo) {
            return (
              <div className="secondActivity">
                <div className="activityInfo">
                  <p className="linkTemp">
                    {getRightTime(activityInfo.node.calendarInfo.date)}
                  </p>
                  <h3>{activityInfo.node.title}</h3>

                  <BlockContent
                    blocks={activityInfo.node._rawDescription}
                    projectId="q2jj4zmh"
                    dataset="production"
                    serializers={serializers}
                  />

                  {/* <p></p> */}
                </div>

                <div className="contactWrapper">
                  <p className="signUp">Tilmeld</p>
                  <p className="contactDate">
                    {getRightTime(activityInfo.node.calendarInfo.date)}
                  </p>
                  <ContactBox
                    data={activityInfo.node.title}
                    name={"contact-form"}
                  />
                </div>
              </div>
            )
          })}
        </InView>

        <InView
          threshold="1"
          onChange={(inView, entry) => {
            if (inView === true) {
              entry.target.firstChild.classList.add("quickFadeUp")
            }
          }}
        >
          <div className="allActivities">
            <AniLink to="/kommende-aktiviteter/" cover bg="#69b56b">
              Se flere aktiviteter
            </AniLink>
          </div>
        </InView>
        <div class="custom-shape-divider-bottom-1592555946">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </section>
  </Layout>
)
export default Index

function getRightLink(linkButton) {
  if (linkButton.link[0] === "/") {
    return (
      <AniLink
        className="buttonTemp"
        cover
        bg="#69b56b"
        to={linkButton.link}
        style={{ cursor: "pointer" }}
      >
        {linkButton.txtt}
      </AniLink>
    )
  } else if (linkButton.link.includes("http") === true) {
    return <a href={linkButton.link}>{linkButton.txtt}</a>
  } else {
    return (
      <Link
        className="buttonTemp"
        activeClass="active"
        to={linkButton.link}
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        style={{ cursor: "pointer" }}
      >
        {linkButton.txtt}
      </Link>
    )
  }
}

export const query = graphql`
  query indexQuery {
    sanityFrontPage {
      title
      description
      linkButton {
        title
        description
        linkButton {
          link
          txtt
        }
        icon {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      button
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      edges {
        node {
          publishedAt(formatString: "DD. MMMM YYYY", locale: "da_DK")
          _rawBody
          title
          slug {
            current
          }
          mainImage {
            hotspot {
              height
              width
              x
              y
            }
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    sanityAnnualMeeting {
      title
      _rawDescription
      calendarInfo {
        location
        date(formatString: "DD. MMMM YYYY | HH:mm", locale: "da_DK")
      }
      button
    }

    allSanityCalendar(limit: 1) {
      edges {
        node {
          title
          _rawDescription
          calendarInfo {
            location
            date(formatString: "DD. MMMM YYYY | HH:mm", locale: "da_DK")
          }
        }
      }
    }
  }
`

function initiateActions(target) {
  // Add right classes to objects

  let getElements = document.querySelectorAll(".lb")

  let newIndex = 0
  for (let index = 0; index < getElements.length; index++) {
    newIndex++
    const element = getElements[index]

    element.classList.add("lb" + newIndex)
  }

  let getNumber = target.className.split("x")[1]

  // Reset all
  document.querySelector(".activeIndicator").classList.remove("activeIndicator")

  if (document.querySelector(".displayContent") !== null) {
    document.querySelector(".displayContent").classList.remove("displayContent")
  }

  document
    .querySelector(".indicator" + getNumber)
    .classList.add("activeIndicator")

  document.querySelector(".lb" + getNumber).classList.add("displayContent")
}

function getRightTime(date) {
  let firstHalf = date.split("|")[0]
  let secondHalf = date.split("|")[1]
  let secondHalfDivided = secondHalf.split(":")[0]
  let newHour = parseInt(secondHalfDivided) + 2
  let secondHalfDividedEnding = secondHalf.split(":")[1]
  return firstHalf + " | " + newHour + ":" + secondHalfDividedEnding
}
